// importing and setting up Font Awesome
import { dom, library } from '@fortawesome/fontawesome-svg-core';

import {
    faTwitter as fabTwitter,
    faLinkedinIn as fabLinkedinIn,
    faFacebookF as fabFacebookF,
    faInstagram as fabInstagram
} from '@fortawesome/free-brands-svg-icons'

import {
    faChevronDown as fasChevronDown,
    faChevronRight as fasChevronRight,
    faChevronUp as fasChevronUp,
    faPause as fasPause,
    faPhone as fasPhone,
    faPlay as fasPlay,
    faVolumeHigh as fasVolumeHigh,
    faVolumeXmark as fasVolumeXmark,
} from '@fortawesome/free-solid-svg-icons'

// load font-awesome libraries
library.add(
    fabFacebookF,
    fabInstagram,
    fabLinkedinIn,
    fabTwitter,
    fasChevronDown,
    fasChevronRight,
    fasChevronUp,
    fasPause,
    fasPhone,
    fasPlay,
    fasVolumeHigh,
    fasVolumeXmark
);

dom.watch()